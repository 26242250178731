import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Flex, Title, Box, Image, Text } from "../utils/system";
import Blocks from "../components/Blocks";

const Blog = ({ data }) => {
  const { title, _rawContent, image, _createdAt } = data.sanityBlog;
  return (
    <Layout dark>
      <Flex pt={5} pb={6}>
        <Flex flexDirection="column">
          <Title fontSize={[5, 6, 7]} px={[5, 6, 7]} textAlign="center" mb={0}>
            {title}
          </Title>
          <Text textAlign="center">{_createdAt}</Text>
        </Flex>
      </Flex>
      <Image image={image.asset.gatsbyImageData} alt={title} />

      <Box px={[3, 5, 7]} py={[4, 6]}>
        <Blocks blocks={_rawContent} />
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query Blog($id: String!) {
    sanityBlog(_id: { eq: $id }) {
      _createdAt(formatString: "DD MMMM YYYY")
      title
      _rawContent
      image {
        asset {
          gatsbyImageData(aspectRatio: 1.9)
        }
      }
    }
  }
`;

export default Blog;
